.carousel-container {
    width: 90%;
    margin: 0 auto;
    margin-top: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Custom shadow */
    border-radius: 10px; /* Rounded corners */
  }

  .carousel-item img {
    height: 400px; /* Set a fixed height for the images */
    width: 100%;
    object-fit:fill; /* This makes sure the image covers the entire area without distortion */
    border-radius: 10px;

  }

  .expertise .card {
    transition: transform 0.3s;
    display: flex;
 
  }
  

  .expertise .card img{
    border-radius: 10px;

  }

 
  .expertise .card:hover {
    transform: scale(1.02);
  }

  .expertise .title{
    text-align: center;
    color: #003050;
  }
  

 .step-img{
    height: 8em;
    padding: 1em;
 }

 .card-text{
  text-align: justify;
 }