/* General card styling, ensuring no hover effect */
.card {
  --bs-card-border-color: transparent; /* Make sure there's no border color */
  --bs-card-box-shadow: none; /* No box shadow */
  transition: none !important; /* Disable any transition effects */
}

/* Specifically disable hover effects */
.work-card:hover {
  transform: none !important; /* Prevent transform on hover */
  box-shadow: none !important; /* Ensure no box shadow on hover */
  border-color: #f4f4f4 !important; /* Keep border color consistent */
  background-color: transparent !important; /* Prevent background color change */
}
/* Main card styling */
.work-card {
  display: flex;
  flex-direction: row;
  align-items: flex-start; /* Align items to the start */
  padding: 1.4rem;
  border: 1px solid #f4f4f4;
  margin: 1rem;
  flex-wrap: wrap; /* Makes it responsive */
  transition: none !important; /* Prevent hover transition effects */
  box-shadow: none !important; /* Ensure no shadow */

  min-height: 200px; /* Set a minimum height to ensure consistency */
  transition: height 0.2s ease; /* Smooth transition on height change */
}

/* Content container */
.card-content {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: flex-start; /* Align items to the start */
  width: 100%;
}

/* Work details section */
.work-details {
  flex: 2;
  padding-right: 1rem;
  display: flex;
  flex-direction: column; /* Align heading and description vertically */
  justify-content: flex-start; /* Align items to the top */
}

.work-details h6 {
  font-weight: bold;
  color: #005080; /* Use your brand color */
  margin-bottom: 0.5rem; /* Space below heading */
}

.work-details p {
  font-size: 0.9rem;
  margin: 0.5rem 0 1rem 0;
}

.work-details ul {
  padding-left: 1rem;
  font-size: 0.9rem;
  color: #6c757d;
}

/* Image and actions container */
.image-and-actions {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.work-image {
  height: 110px;
  width: 110px;
  border-radius: 8px;
  object-fit: cover; /* Maintain aspect ratio and fill the container */
  margin-bottom: 0.4rem;
  margin-top: 0.4rem;
}

/* Button styling */
.add-btn,
.admin-button {
  margin-top: 0.5rem;
  width: 100%; /* Full width on smaller screens */
}

/* Quantity controls */
.quantity-controls {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
}

.quantity {
  padding: 0 0.5rem;
}

.options-text {
  color: #6c757d;
  margin-top: 0.5rem;
}

.custom-outline-button {
  color: #003050 !important;
  border-color: #003050 !important;
}

.custom-outline-button {
  border-color: #ffffff !important;
  color: #003050 !important; /* Optional: Change text color on hover */
  padding: 0.3rem 1rem !important;
}

.custom-outline-button:hover {
  background-color: #ffffff !important;
  border-color: #003050 !important;
  color: #003050 !important;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .work-card {
      flex-direction: column;
      align-items: flex-start;
  }

  .card-content {
      flex-direction: column;
  }

  .work-details {
      padding-right: 0;
      text-align: center;
      width: 100%;
  }

  .image-and-actions {
      width: 100%;
      margin-top: 1rem;
  }

  .add-btn,
  .admin-button {
      width: 100%; /* Full width button on smaller screens */
  }
}


/* */
.modal .modal-title{
  font-weight: bold;
  color: #003050;
  padding-left: 15px;
}


/* Dotted border for the modal header */
.modal .modal-header {
  border-bottom: 2px dashed #ccc; /* Change color as needed */
}

/* Dotted border for the modal footer */

.custom-btn {
  background-color: #003050;
  color: white;
  width: 25%;
  font-weight: bold;
  padding: 10px;
  border: none; /* Ensure no default border */
  cursor: pointer; /* Change cursor on hover */
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.custom-btn:hover {
  background-color: #0f5c7a;
}

.custom-btn:focus {
  outline: none; /* Remove the default focus outline */
  box-shadow: 0 0 5px rgba(15, 92, 122, 0.5); /* Optional: add a custom focus style */
}

.custom-btn:active {
  background-color: #0f5c7a; /* Ensure the same color as hover on click */
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2); /* Optional: change shadow for active state */
}



