.cartEmpty {
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    align-items: center; /* Center elements horizontally */
    justify-content: center; /* Center elements vertically */
    text-align: center; /* Optional: Center-align the text */
    padding-top: 40px;
    gap: 10px; /* Adjust the gap size as needed */
    
  }

  .cartEmpty h4{
    color: rgb(163, 1, 1);
    font-weight: bold;
  }

  .add-btn {
    width: 100px;
    font-size: 16px;
    padding: 8px 0;
    border-radius: 4px;
  }
  
  /* Container for the quantity controls */
  .quantity-controls {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* Style for the quantity number */
  .quantity-controls .quantity {
    font-size: 16px;
    margin: 0 12px;
    font-weight: bold;
  }
  
  /* Style for increment and decrement buttons */
  .quantity-controls .btn {
    width: 30px;
    height: 30px;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    padding: 0;
  }
  
  .quantity-controls .btn-outline-secondary {
    background-color: #fff;
    border-color: #007bff;
    color: #007bff;
    transition: background-color 0.2s ease, color 0.2s ease;
  }
  
  .quantity-controls .btn-outline-secondary:hover {
    background-color: #007bff;
    color: #fff;
  }






  /**/

  .cart-container {
    padding: 15px;
    border-radius: 10px;
    border: 1px solid  rgb(227,227,227) ;
  }
  
  .cart-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .cart-item {
    padding: 10px 0;
    display: flex;
    flex-direction: column;
  }
  
  .cart-item-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .cart-item-name {
    font-size: 1rem;
    font-weight: 500;
    margin-right: auto;
  }
  
  .quantity-controls {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .quantity-btn {
    border: none;
    background-color: transparent;
    color: #6c757d;
    padding: 4px 8px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .quantity {
    font-size: 1rem;
  }
  
  .cart-item-price {
    font-size: 1rem;
    font-weight: 600;
    color: #495057;
    margin-left: 1rem;
  }
  
  .item-separator {
    border-top: 1px solid ;
    margin: 10px 0;
  }
  
  .cart-empty {
    text-align: center;
    padding: 20px;
  }
  