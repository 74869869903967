.password-rules {
    list-style: none;
    padding: 0;
    margin: 10px 0;
  }
  
  .password-rules li {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    font-size: 0.9rem;
  }
  
  .valid {
    color: #28a745; /* Green for valid */
  }
  
  .invalid {
    color: #d9534f; /* Red for invalid */
  }
  
  .error {
    margin-top: 10px;
    font-size: 0.85rem;
  }
  
  .valid-icon,
  .invalid-icon {
    margin-right: 8px;
  }
  