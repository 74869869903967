

.full-height-right-modal .modal-dialog {
  position: fixed;
  right: 0;
  top: 0;
  left: auto;
  height: 100vh !important; /* Full viewport height */
  width: 100%; /* Full width of specified modal */
  margin: 0 !important;
  max-width: 100%; /* Prevents Bootstrap's max-width */
  transform: none !important; /* Prevents centering */
}

.full-height-right-modal .modal-content {
  height: 100vh; /* Full viewport height */
  overflow-y: auto; /* Enable scrolling for overflow */
  border-radius: 0;
  border: none;
}

  
.error {
  color: #d9534f; /* Bootstrap danger color */
  font-size: 0.875rem; /* Smaller font for better readability */
  margin-top: 0.25rem;
  display: flex;
  align-items: center;
}

.error-icon {
  margin-right: 0.5rem; /* Space between icon and text */
  color: #d9534f;
}