.container {
    margin-top: 20px;
}

.group-items {
    border: 1px solid grey;
    border-radius: 10px;
    margin: 20px 0;
    padding: 20px;
    background-color: #f8f9fa; /* Light background */
}

.group-items h2 {
    font-size: 1.5rem;
    margin-bottom: 15px;
    color: #333; /* Darker text for better readability */
}



.work-card {
    margin-bottom: 20px; /* Space between work cards */
    transition: transform 0.2s; /* Animation on hover */
}

.work-card:hover {
    transform: scale(1.05); /* Slightly enlarge card on hover */
}
