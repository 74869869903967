 .service-card{
    margin: 5%;
    overflow: hidden;
 }
  /* Style for the card image */
  .card-image img {
    width: 100%;
    height: auto;
    overflow: hidden;
    border-radius: 12px 0 0 12px;
  }
  
  /* Left-aligned image */
  .image-left .card-image {
    order: 0; /* Image comes first */
  }
  
  /* Right-aligned image */
  .image-right .card-image {
    order: 1; /* Image comes second */
  }
  
  /* Padding and layout for the card content */
  .card-content {
    display: flex;
    flex-direction: column;
    justify-content: center;

  }
  
  .card-body {
    text-align: left;
  }
  
  /* Adjust image size and alignment */
  .card-image {
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }


  /* Base styling */
.service-card {
  margin-bottom: 20px;
  border: none;
  transition: all 0.3s ease;
}

/* Initially hidden content */
.content-hidden {
  opacity: 0;
  transform: translateX(20px);  /* Slide effect from the right */
}

/* Animation effect on load */
.content-appear {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 0.6s ease, transform 0.6s ease;  /* Adjust speed as needed */
}

/* Adjust layout for image positioning */
.image-left .card-image {
  order: 1;
}
.image-left .card-content {
  order: 2;
}

.image-right .card-image {
  order: 2;
}
.image-right .card-content {
  order: 1;
}








/* Styles for the QuoteWorks Component */

/* Container styles */
.Container {
  padding: 2rem 1rem;
  max-width: 1200px;
  margin: auto;
}



.card-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.card-image:hover img {
  transform: scale(1.1);
}

/* Content styles */
.card-content {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card-content .content-appear {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.card-content .content-hidden {
  opacity: 0;
  transform: translateX(20px);
}

.card-content .card-title {
  font-size: 1.75rem; /* Larger font size */
  font-weight: 700; /* Bolder text */
  color: #2c3e50; /* Darker shade for emphasis */
  letter-spacing: 1px; /* Modern letter spacing */
  margin-bottom: 0.75rem;
}

.card-content .card-subtitle {
  font-size: 1.25rem; /* Slightly smaller than title */
  font-weight: 400; /* Regular weight */
  font-style: italic; /* Adds a softer look */
  color: #7f8c8d; /* Muted color */
  margin-bottom: 1rem;
}

.card-content .card-text {
  font-size: 1rem; /* Standard readable size */
  line-height: 1.8; /* Improved line spacing */
  color: #555; /* Neutral gray color */
  margin-bottom: 1.5rem;
}

/* Button styles */
.card-content .btn {
  margin-right: 0.5rem;
  margin-top: 0.5rem;
  color: #3498db;
  border-color: #3498db;
  color: white;
  transition: background-color 0.3s, color 0.3s;
}


.card-content .btn:hover {
  background-color: #3498db;
  color: white;
  border-color: #2980b9;
}

/* Primary button styles */
.card-content .btn-primary {
  background-color: #3498db;
  color: white;
  border-color: #3498db;
}

.card-content .btn-primary:hover {
  background-color: #2980b9;
  border-color: #2980b9;
}

.custom-btn {
  background-color: #003050;
  color: #FFFFFF;
  font-size: large;
  border-radius: 7px;
  padding: 5px 17px 5px 17px;
  border: none;
}

/* Get a Quote button styles */
.card-content .custom-btn {
  padding: 0.75rem 1rem; /* Comfortable padding */
  font-size: 1.0rem; /* Slightly larger font size */
  font-weight: bold; /* Emphasizes the button */
  border-radius: 7px; /* Smooth round edges */
  background-color: #003050; /* Primary color */
  color: white; /* Contrasting text color */
  border: none; /* Removes border */
  transition: all 0.3s ease; /* Adds hover animation */
}

.card-content .custom-btn:hover {
  background-color: #003050; /* Slightly darker on hover */
  transform: scale(1.05); /* Subtle zoom effect */
}


/* Responsive styles */
@media (max-width: 768px) {
  .card-content {
    padding: 1.5rem;
  }

  .card-content .card-title {
    font-size: 1.5rem;
  }

  .card-content .card-subtitle {
    font-size: 1rem;
  }

  .card-content .card-text {
    font-size: 0.9rem;
  }
}






