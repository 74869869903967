
.main-navbar {
  border-bottom: 1px solid #EDEDED; 
  padding-top: 0px !important;
  padding-bottom: 10px !important;
}

.main-navbar .logo {
  font-family: "Courgette", cursive;
  font-weight: 500;
  font-size: 28px;
  font-weight: bold;
  font-style: normal;
  color: #003050;
}

.main-navbar img{
  border-radius: 50px;
  margin: 0px !important;
}

.search-bar {
  max-width: 400px;  /* Adjust as needed */
  width: 100%;
}


.nav-link {
  margin-left: 15px;
}

.cart-badge {
  top: 0;
  right: 0;
  font-size: 0.75rem;
  padding: 0.25em 0.5em;
}

.icon{
    margin-right: 10px;
    font-size: larger;
  }

  .navbar {
    position: relative;
  }
  
  .dropdown-menu {
    position: absolute; /* Ensure it's positioned properly */
    left: 0;
    right: 0;
    max-width: none; /* Allow it to expand fully */
  }

  .search-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
  }
  
  input.search-input {
   
    padding-left: 30px; /* Adjust the padding to make space for the icon */
  }
  
  .search-icon {
    position: absolute;
    left: 10px; /* Adjust the positioning of the icon */
  }
  .close-icon {
    position: absolute;
    right: 10px; /* Adjust the positioning of the icon */
  }


.search-results .list-group-item {
  cursor: pointer;
}

.search-results .list-group-item:hover {
  background-color: #f8f9fa;
}

.search-results {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
}


.second-navbar{ 
  background-color: #003050; /* Light background */
  color: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 0 !important;
  margin: 0px !important;
  
}
.second-navbar p, .second-navbar p:hover{
  color: white;
  font-family: "Courgette", cursive;
  font-weight: lighter;
  margin: 0.3rem;
  font-size: 1rem;
}




