html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;  /* Prevent horizontal scroll */
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Full height of the viewport */
}

/* Make the main content grow to fill space */
.content-container {
  flex: 1; /* Allow main content to grow and take available space */

}

footer {
  margin-top: auto;
  padding: 10px; /* Adjust padding as needed */
  background-color: #143557; 
}

/* Prevent the page from scrolling past the footer */
body {
  overflow-y: scroll;
}

button.custom-btn {
  background-color: #003050;
  color: #FFFFFF;
  font-size: large;
  border-radius: 7px;
  padding: 5px 17px 5px 17px;
  border: none;
}

body h3{
  color: #003050;
}
