.service-form {
    max-width: 90%;
    margin: 4%;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  
  .service-form h5 {
    font-size: 1.25rem;
    color: #495057;
  }
  
  .service-form .form-group {
    margin-bottom: 1.25rem;
  }
  
  .service-form .form-check-label {
    color: #495057;
  }
  
  .service-form .form-control,
  .service-form .form-select {
    border-radius: 0.3rem;
    padding: 0.6rem;
  }
  
  .service-form button {
    padding: 0.6rem 1.5rem;
    border-radius: 0.3rem;
  }
  
  .service-form h6 {
    font-size: 1rem;
    color: #6c757d;
  }

  .badge{
    background-color: #003050;
    color: #fff
  }
  
  /* Responsive Design */
  @media (max-width: 576px) {
    .service-form {
      padding: 1rem;
    }
  }
  