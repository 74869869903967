.footer {
    background-color: #f5f5f5;
    color: black;
    text-align: center;
    padding-top: 40px;
    margin-top:40px;
    width: 100%;
    bottom: 0;
    
}

  
  .footer .container {
    max-width: 1200px; /* Adjust as needed */
  }
  
  .footer .nav-link {
    color: #6c757d;
  }
  
  .footer .nav-link:hover {
    color: #343a40;
  }
  
  .footer h5 {
    margin-bottom: 1rem;
  }
  
  .footer .d-flex {
    gap: 10px;
  }
  
  @media (max-width: 576px) {
    .footer .nav {
      text-align: center;
    }
  }


  