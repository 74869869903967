.horizontal-scroll {
    display: flex;
    overflow-x: auto;
    gap: 10px;
    padding: 10px 0;
    margin-bottom: 20px;
  }
  
  .horizontal-scroll .date-button {
    min-width: 120px;
  }

  
  