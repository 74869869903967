/* Styling for the category modal */
.right-modal  {
  border-radius: 15px;
  padding: 20px;
  
}



.tagline{
  font-family: "Courgette", cursive;
  display: flex;
  justify-content: center;
  text-align: center;
  color: #005080;
  font-weight: 600px;
}



.category-option {
  cursor: pointer;
  width: 30%; /* Adjust width as needed */
  margin: 0 10px; /* Add horizontal margin for spacing */
}


.category-selection {
  padding: 10px 20px;
}


.icon-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border: 1.5px solid #ddd;
  border-radius: 15px;
  width: 140px;
  transition: 0.3s;
  color: #005080;
}

.icon-container span {
  padding-top: 20px;
  font-size: 1rem;
  font-weight: 500;
  color: #005080;
}

.icon-container.selected {
  border-color: #003050;
  color:#003050;
  background-color: #f0f8ff;
  box-shadow: 0px 4px 8px rgba(0, 48, 80, 0.2);
}

.custom-btn {
  background-color: #003050;
  color: white;
  font-weight: bold;
  padding: 10px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

button.custom-btn:hover, button.custom-btn:active{
    background-color: #003050;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
}

button.custom-btn:disabled {
  background-color: #032444;
  color: #e0e0e0;
  box-shadow: none;
}


